import { useEffect, useCallback, useState } from "react";
import styled from "styled-components";
const breakpoints = [
  {
    min: 0,
    max: 640,
    image: {
      top: "/images/Frame1_390.webp",
      gif: "/gif/Video_390.gif",
      middle: "/images/Frame3_390.png",
      bottom: "/images/Frame4_390.png",
      logo: "/images/logo_white_390.png",
    },
  },
  {
    min: 641,
    max: 1024,
    image: {
      top: "/images/Frame1_1024.webp",
      gif: "/gif/Video_1024.gif",
      middle: "/images/Frame3_1024.png",
      bottom: "/images/Frame4_1024.png",
      logo: "/images/logo_white_1920&1024.png",
    },
  },
  {
    min: 1025,
    max: 1920,
    image: {
      top: "/images/Frame1_1920.webp",
      gif: "/gif/Video_1920.gif",
      middle: "/images/Frame3_1920.webp",
      bottom: "/images/Frame4_1920.png",
      logo: "/images/logo_white_1920&1024.png",
    },
  },
];

function App() {
  const [imgsSrc, setImgsSrc] = useState({
    top: "/images/Frame1_1920.webp",
    gif: "/gif/Video_1920.gif",
    middle: "/images/Frame3_1920.webp",
    bottom: "/images/Frame4_1920.png",
    logo: "/images/logo_white_1920&1024.png",
  });
  const handleViewportChange = useCallback(() => {
    const windowWidth = window.innerWidth;
    breakpoints.forEach(({ min, max, image }) => {
      if (windowWidth >= min && windowWidth <= max) {
        setImgsSrc({
          top: image.top,
          gif: image.gif,
          middle: image.middle,
          bottom: image.bottom,
          logo: image.logo,
        });
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleViewportChange);
    return () => {
      window.removeEventListener("resize", handleViewportChange);
    };
  }, [handleViewportChange]);

  useEffect(() => {
    handleViewportChange();
  }, [handleViewportChange]);

  // const articleUrl = "https://www.wegoing.io/";
  // const title = "we.io";
  // const imgSrc = "https://storage.googleapis.com/wegoing/images/logo.png";
  return (
    <>
      {/* <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${articleUrl}&title=${title}&picture=${imgSrc}`}
      >
        share
      </a> */}
      <StyledDiv>
        <img src={imgsSrc.top} alt="" />
      </StyledDiv>
      <StyledDiv>
        <img src={imgsSrc.gif} alt="" />
      </StyledDiv>
      <StyledDiv>
        <img src={imgsSrc.middle} alt="" />
      </StyledDiv>
      <StyledDiv>
        <img src={imgsSrc.bottom} alt="" />
      </StyledDiv>

      <BottomBlock>
        <h1>Contact Us</h1>
        <h2>
          If you want to know more about WOWS, please let us know,
          <br />
          we will contact you by customer service staff
        </h2>
        <BottomLogo>
          <img src={imgsSrc.logo} alt="logo" />
        </BottomLogo>
        <ServiceMail href="mailto:service@wows.ai">service@wows.ai</ServiceMail>
        <p>
          301E,No. 502, Sec. 2, Ren’ai Rd., Linkou Dist., New Taipei City
          244020, Taiwan
        </p>
        <p>WOWS Marketing Technology co., ltd.</p>
      </BottomBlock>
    </>
  );
}

const StyledDiv = styled.div`
  max-width: 100vw;
  display: flex;
  align-items: stretch;

  > img {
    width: 100%;
    height: auto;
  }
`;

const BottomBlock = styled.footer`
  background: #1376ad;
  padding: 120px 30px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > h1 {
    font-weight: 800;
    font-size: 4.62rem;
    margin: 0 0 10px 0;
  }

  > h2 {
    margin: 0 0 67px 0;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 150%;
  }

  > p {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  /* @media (max-width: 1024px) {
    > h1 {
      font-size: 4.7vw;
    }

    > h2 {
      font-size: 2.4vw;
    }
  } */

  @media (max-width: 640px) {
    padding: 60px 22%;

    > h1 {
      font-size: 1.5rem;
    }
    > h2 {
      font-size: 0.75rem;
      margin-bottom: 1.25rem;
    }
    > p {
      font-size: 0.75rem;
    }
  }
`;

const BottomLogo = styled.div`
  width: 271px;
  height: 47px;
  margin: 3.75rem 0 1.8rem 0;
  > img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 640px) {
    width: 156px;
    height: 27px;
    margin: 1.5rem 0;
  }
`;

const ServiceMail = styled.a`
  color: white;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-decoration: none;
`;

export default App;
